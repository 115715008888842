import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";

import ProductSelect from "../../components/ProductSelect/product-select";
import { NextCollection } from "../../components/ProductSelect/next-collection";
import { ProductTypes } from "../../types";

const comboOrder = [
	"candycane",
	"gingerbread",
	"angel",
	"small",
	"smedium",
	"medium",
	"xl",
];
const doubleOrder = ["medium", "xl", "tiny"];

class GearSelect extends Component {
	render() {
		const domain =
			process.env.GATSBY_MYSHOPIFY_URL || "tearribles.myshopify.com";

		const categorizedCombos = [];

		const halloweenCombos =
			this.props.data.allShopifyCollection.edges?.[0]?.node.products.filter(
				(node) => {
					if (!this.props.preOrderAuthorized) {
						return false;
					}

					if (
						node.tags.includes("Halloween") &&
						!node.tags.includes("Hide")
					) {
						categorizedCombos.push(node.id);
						return true;
					}

					return false;
				},
			);

		const ultimateCombo =
			this.props.data.allShopifyCollection.edges?.[0]?.node.products.filter(
				(node) => {
					if (
						node.tags.includes("Ultimate") &&
						!node.tags.includes("Hide")
					) {
						categorizedCombos.push(node.id);
						return true;
					}

					return false;
				},
			);

		const sniffPlayCombo =
			this.props.data.allShopifyCollection.edges?.[0]?.node.products.filter(
				(node) => {
					if (
						node.tags.includes("Sniff & Play") &&
						!node.tags.includes("Hide")
					) {
						categorizedCombos.push(node.id);
						return true;
					}

					return false;
				},
			);

		const chewPlayCombo =
			this.props.data.allShopifyCollection.edges?.[0]?.node.products.filter(
				(node) => {
					if (
						node.tags.includes("Chew & Play") &&
						!node.tags.includes("Hide")
					) {
						categorizedCombos.push(node.id);
						return true;
					}

					return false;
				},
			);

		const doubleFunCombo =
			this.props.data.allShopifyCollection.edges?.[0]?.node.products.filter(
				(node) => {
					if (
						node.tags.includes("Double Fun") &&
						!node.tags.includes("Hide")
					) {
						categorizedCombos.push(node.id);
						return true;
					}

					return false;
				},
			);

		const christmasCombos =
			this.props.data.allShopifyCollection.edges?.[0]?.node.products.filter(
				(node) => {
					if (
						node.tags.includes("Christmas Combo") &&
						!node.tags.includes("Hide")
					) {
						categorizedCombos.push(node.id);
						return true;
					}

					return false;
				},
			);

		const vipCombos =
			this.props.data.allShopifyCollection.edges?.[0]?.node.products.filter(
				(node) => {
					if (
						node.tags.includes("VIP") &&
						!node.tags.includes("Christmas Combo") &&
						!node.tags.includes("Hide")
					) {
						categorizedCombos.push(node.id);
						return true;
					}

					return false;
				},
			);

		const doorBusterCombos =
			this.props.data.allShopifyCollection.edges?.[0]?.node.products.filter(
				(node) => {
					if (
						node.tags.includes("Door Buster") &&
						!node.tags.includes("Hide")
					) {
						categorizedCombos.push(node.id);
						return true;
					}

					return false;
				},
			);

		const combosData =
			this.props.data.allShopifyCollection.edges?.[0]?.node.products.filter(
				(node) => {
					return (
						!node.tags.includes("Hide") &&
						!categorizedCombos.includes(node.id)
					);
				},
			);

		return (
			<>
				{halloweenCombos && halloweenCombos.length ? (
					<ProductSelect
						title="Halloween Combos"
						sort={(a, b) => {
							if (a.productType !== ProductTypes.CHEWPLAY) {
								return 0;
							}

							const aSize = comboOrder.indexOf(a.size);
							const bSize = comboOrder.indexOf(b.size);

							if (aSize < bSize) return -1;
							if (aSize > bSize) return 1;
							return 0;
						}}
						{...this.props}
						displayPrice={false}
						data={{
							allShopifyCollection: {
								edges: [
									{
										node: {
											products: halloweenCombos,
										},
									},
								],
							},
						}}
					/>
				) : null}
				{christmasCombos && christmasCombos.length ? (
					<ProductSelect
						title="Christmas Combos"
						sort={(a, b) => {
							if (a.productType !== ProductTypes.CHEWPLAY) {
								return 0;
							}

							const aSize = comboOrder.indexOf(a.size);
							const bSize = comboOrder.indexOf(b.size);

							if (aSize < bSize) return -1;
							if (aSize > bSize) return 1;
							return 0;
						}}
						{...this.props}
						displayPrice={false}
						data={{
							allShopifyCollection: {
								edges: [
									{
										node: {
											products: christmasCombos,
										},
									},
								],
							},
						}}
					/>
				) : null}
				{ultimateCombo && ultimateCombo.length ? (
					<ProductSelect
						title="Ultimate Combos"
						sort={(a, b) => {
							if (a.productType !== ProductTypes.ULTIMATE) {
								return 0;
							}

							const order = [
								"the-ultimate-combo-small",
								"the-ultimate-combo-medium",
								"the-ultimate-combo-xl",
							];

							const aSize = order.indexOf(a.handle);
							const bSize = order.indexOf(b.handle);

							if (aSize < bSize) return -1;
							if (aSize > bSize) return 1;
							return 0;
						}}
						{...this.props}
						displayPrice={false}
						data={{
							allShopifyCollection: {
								edges: [
									{
										node: {
											products: ultimateCombo,
										},
									},
								],
							},
						}}
					/>
				) : null}
				{/* vipCombos && vipCombos.length ? (
					<ProductSelect
						title="VIP Combos"
						sort={(a, b) => {
							if (a.productType !== ProductTypes.CHEWPLAY) {
								return 0;
							}

							const aSize = comboOrder.indexOf(a.size);
							const bSize = comboOrder.indexOf(b.size);

							if (aSize < bSize) return -1;
							if (aSize > bSize) return 1;
							return 0;
						}}
						{...this.props}
						displayPrice={false}
						data={{
							allShopifyCollection: {
								edges: [
									{
										node: {
											products: vipCombos,
										},
									},
								],
							},
						}}
					/>
				) : null */}
				{chewPlayCombo && chewPlayCombo.length ? (
					<ProductSelect
						title="Chew & Play"
						sort={(a, b) => {
							if (a.productType !== ProductTypes.CHEWPLAY) {
								return 0;
							}

							const aSize = comboOrder.indexOf(a.size);
							const bSize = comboOrder.indexOf(b.size);

							if (aSize < bSize) return -1;
							if (aSize > bSize) return 1;
							return 0;
						}}
						{...this.props}
						displayPrice={false}
						data={{
							allShopifyCollection: {
								edges: [
									{
										node: {
											products: chewPlayCombo,
										},
									},
								],
							},
						}}
					/>
				) : null}
				{sniffPlayCombo && sniffPlayCombo.length ? (
					<ProductSelect
						title="Sniff & Play"
						sort={(a, b) => {
							if (a.productType !== ProductTypes.SNIFFPLAY) {
								return 0;
							}

							const aSize = comboOrder.indexOf(a.size);
							const bSize = comboOrder.indexOf(b.size);

							if (aSize < bSize) return -1;
							if (aSize > bSize) return 1;
							return 0;
						}}
						{...this.props}
						displayPrice={false}
						data={{
							allShopifyCollection: {
								edges: [
									{
										node: {
											products: sniffPlayCombo,
										},
									},
								],
							},
						}}
					/>
				) : null}
				{doubleFunCombo && doubleFunCombo.length ? (
					<ProductSelect
						title="Double Fun"
						sort={(a, b) => {
							const aSize = doubleOrder.indexOf(a.size);
							const bSize = doubleOrder.indexOf(b.size);

							if (aSize < bSize) return -1;
							if (aSize > bSize) return 1;
							return 0;
						}}
						{...this.props}
						displayPrice={false}
						data={{
							allShopifyCollection: {
								edges: [
									{
										node: {
											products: doubleFunCombo,
										},
									},
								],
							},
						}}
					/>
				) : null}
				{doorBusterCombos && doorBusterCombos.length ? (
					<ProductSelect
						title="Door Buster Combos"
						sort={(a, b) => {
							if (a.productType !== ProductTypes.CHEWPLAY) {
								return 0;
							}

							const aSize = comboOrder.indexOf(a.size);
							const bSize = comboOrder.indexOf(b.size);

							if (aSize < bSize) return -1;
							if (aSize > bSize) return 1;
							return 0;
						}}
						{...this.props}
						displayPrice={false}
						data={{
							allShopifyCollection: {
								edges: [
									{
										node: {
											products: doorBusterCombos,
										},
									},
								],
							},
						}}
					/>
				) : null}
				{combosData && combosData.length ? (
					<ProductSelect
						title="Combos"
						sort={(a, b) => {
							const aSize = comboOrder.indexOf(a.size);
							const bSize = comboOrder.indexOf(b.size);

							if (aSize < bSize) return -1;
							if (aSize > bSize) return 1;
							return 0;
						}}
						{...this.props}
						displayPrice={false}
						data={{
							allShopifyCollection: {
								edges: [
									{
										node: {
											products: combosData,
										},
									},
								],
							},
						}}
					/>
				) : null}
				<NextCollection handleOverride="combos" {...this.props} />
			</>
		);
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			query comboQuery {
				allShopifyCollection(filter: { title: { in: "Combos" } }) {
					edges {
						node {
							title
							handle
							products {
								id
								shopifyId
								legacyResourceId
								title
								descriptionHtml
								productType
								tags
								handle
								collections {
									title
								}
								# images {
								#     originalSrc
								#     localFile {
								#         childImageSharp {
								#             fluid {
								#                 ...GatsbyImageSharpFluid_withWebp_noBase64
								#             }
								#         }
								#     }
								# }
								featuredImage {
									originalSrc
									localFile {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid_withWebp_noBase64
											}
										}
									}
								}
								variants {
									title
									id
									shopifyId
									legacyResourceId
									sku
									price
									compareAtPrice
									availableForSale
								}
							}
						}
					}
				}
			}
		`}
	>
		{(data) => <GearSelect data={data} {...props} />}
	</StaticQuery>
);
